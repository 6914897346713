import React from 'react';
import { useForm, ValidationError } from "@formspree/react";
import {
	fieldWrapper,
	button
} from './layout.module.css'

export default function ContactForm() {
  const [state, handleSubmit] = useForm("mayvzzwe");

  if (state.succeeded) {
    return <p>Thanks for your interest in Sitekeeper! You will hear back from us very soon.</p>;
  }

  return (
	<form onSubmit={handleSubmit}>
		<div className={fieldWrapper}>
			<label htmlFor="name">Name</label>
			<input id="name" type="text" name="name" />
			<ValidationError prefix="Name" field="name" errors={state.errors} />
		</div>
		<div className={fieldWrapper}>
			<label htmlFor="email">Email Address</label>
			<input id="email" type="email" name="email" />
			<ValidationError prefix="Email" field="email" errors={state.errors} />
		</div>
		<div className={fieldWrapper}>
		<label htmlFor="message">Message</label>
		<textarea id="message" name="message" />
		<ValidationError prefix="Message" field="message" errors={state.errors} />
		</div>
		<button className={button} type="submit" disabled={state.submitting}>Submit</button>
		<ValidationError errors={state.errors} />
	</form>
  );
}
