import React from "react"
import Layout from '../components/layout'
import ContactForm from "../components/contact-form";

const Contact = () => {
	return (
		<Layout pageTitle="Contact Page">
			<section data-sal="slide-up" data-sal-easing="ease" data-sal-duration="500">
				<h1>Ready to have the best website of your life?</h1>
				<p>Fill out the form below to get started.</p>
				<ContactForm />
			</section>
		</Layout>
	)
}

export default Contact
